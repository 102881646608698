<template>
    <div>
        <custom-header description="A list of all my presentable projects" title="Projects"/>

        <div class="projects">
            <project-entry v-for="(p, i) in projects" :key="p.id" :index="i" :project-data="p"/>
        </div>
        <p>{{ info }}</p>
    </div>
</template>

<script>
import CustomHeader from "@/components/templates/CustomHeader";
import ProjectEntry from "@/components/pages/projects/ProjectEntry";
import axios from "axios";
import conf from "@/config.json"

export default {
    name: "Projects",
    metaInfo: {
        title: 'Projects',
        meta: [
            {property: 'og:title', content: 'Projects'},
            {property: 'og:site_name', content: 'strukteon.net'},
            {property: 'og:type', content: 'website'},
            {property: 'og:description', content: 'A list of my projects'}
        ]
    },
    components: {
        CustomHeader,
        ProjectEntry
    },
    data() {
        return {
            projects: [],
            info: ''
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .get(conf[process.env.NODE_ENV].backend_host + '/api/projects')
            .then(response => {
                next(vm => (vm.projects = response.data))
            })
    }
}
</script>

<style scoped>

</style>