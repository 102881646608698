<template>
    <div :id="projectData._id" class="project-entry">
        <div v-if="index % 2 === 0" ref="img-wrapper" class="project-img">
            <img :src="this.$config.backend_host + projectData.image_url">
            <span class="download-count">{{ projectData.download_count }} Downloads</span>
        </div>


        <div :class="{'project-content': true, 'bg-dark': index % 2 === 1}">
            <div>
                <h3>{{ projectData.title }}</h3>
                <p>{{ projectData.short_description }}</p>
                <b-button v-for="btn in projectData.buttons" :key="btn.title" :href="btn.is_external ? btn.action : ''"
                          :target="btn.is_external ? '_blank' : ''" :to="btn.is_external ? '' : btn.action"
                          :variant="btn.button_variant"
                          class="btn">{{ btn.title }}
                </b-button>

                <b-dropdown v-if="projectData.show_download" :split-href="getDownloadLink(projectData.available_versions[0].server_path)" left split
                            text="Download"
                            variant="info">
                    <b-dropdown-item :href="getDownloadLink(projectData.available_versions[0].server_path)">
                        {{ projectData.available_versions[0].version }} (newest)
                    </b-dropdown-item>
                    <div v-if="projectData.available_versions.length > 1">
                        <b-dropdown-divider/>
                        <b-dropdown-item v-for="v in projectData.available_versions.slice(1, 5)" :key="v.version"
                                         :href="getDownloadLink(v.server_path)">{{ v.version }}
                        </b-dropdown-item>
                        <div v-if="projectData.available_versions.length > 5">
                            <b-dropdown-divider/>
                            <b-dropdown-item v-v-b-modal.modal-select-version>More...</b-dropdown-item>

                            <b-modal id="modal-select-version" centered class="p-none" hide-footer scrollable>
                                <b-list-group flush>
                                    <b-list-group-item v-for="v in projectData.available_versions" :key="v.version"
                                                       :href="getDownloadLink(v.server_path)" class="d-inline-flex">
                                        <span class="d-inline-block w-auto font-weight-bold">v{{ v.version }}</span>
                                        <span class="d-inline-block ml-auto">{{
                                                v.publication_date.split("T")[0]
                                            }}</span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-modal>
                        </div>
                    </div>
                </b-dropdown>
            </div>
        </div>


        <div v-if="index % 2 === 1" class="project-img" ref="img-wrapper">
            <img :src="this.$config.backend_host + projectData.image_url">
            <span class="download-count">{{ projectData.download_count }} Downloads</span>
        </div>
    </div>
</template>

<script>
import {
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    VBModal,
    BListGroup,
    BListGroupItem
} from 'bootstrap-vue';

export default {
    name: "ProjectEntry",
    components: {
        BButton,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BModal,
        BListGroup,
        BListGroupItem
    },
    directives: {
        VBModal
    },
    props: {
        projectData: Object,
        index: Number
    },
    methods: {
        getDownloadLink(file) {
            return '/api/files/' + this.projectData._id + '/versions/' + file;
        }
    },
    mounted() {
        this.projectData.available_versions.sort((x, y) => {
            const x_split = x.version.split(".");
            const y_split = y.version.split(".");
            const max = Math.max(x_split.length, y_split.length);

            for (let i = 0; i < max; i++) {
                if (x_split[i] > y_split[i]) return -1;
                else if (x_split[i] < y_split[i]) return 1;
            }

            return 0;
        });


        if ('#' + this.projectData._id === this.$route.hash)
            this.$utils.smoothScroll(this.$route.hash, false)
    }
}

</script>

<style>
.modal-body {
    padding: 0;
}
</style>

<style scoped>

.project-entry {
    display: flex;
    color: white;
    background-color: rgba(0, 0, 0, 0.075);
    flex-wrap: wrap;
}

.bg-dark {
    background-color: rgba(0, 0, 0, 0.075) !important;
}

div .project-img {
    min-width: min(1024px, 40%);
    width: 20em;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.project-img img {
    width: 100%;
    min-width: 512px;
}

.project-img .download-count {
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    right: 1em;
    top: 1em;
    padding: 0 8px 2px 8px;
    border: transparent 1px solid;
    border-radius: 4px;
}

h3 {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.225em;
}

.project-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding-top: 4em;
    padding-bottom: 4em;
}

.project-content .btn, .project-content .btn-group {
    width: fit-content;
    margin: 0.2em;
}
</style>